.won-loss {
  #quote_tab.wl-groups {
    padding-top: .5rem;
  }

  .wl-job {
    .wl-job-header {
      border-bottom: 1px solid $black;
      padding-bottom: 15px;
    }

    .wl-default-winning-bidder {
      float: right;
      margin-bottom: 1rem;

      label {
        font-weight: normal;
      }
    }
  }

  .wl-groups {
    clear: both;
  }

  .wl-flex {
    display: flex;
    justify-content: space-between;
  }

  .wl-group-header {
    .wl-count {
      align-self: center;
      font-size: .9rem;
    }
  }

  .wl-line-header-container {
    background-color: $light-gray;
    color: $darker-gray;
    padding: .6rem;

    .wl-line-header {
      font-size: 1.05rem;
      font-weight: 700;
    }

    .wl-count {
      align-self: center;
      font-size: .8rem
    }
  }

  .wl-line {
    display: flex;
    margin-bottom: 1px;
    padding-left: 2rem;

    .wl-description {
      align-self: center;
    }

    .wl-buttons {
      display: flex;

      .btn {
        border-radius: 0;
        margin-right: 2px;
        width: 5rem;
      }

      .wl-line-winning-bidder {
        padding: .5rem;
        width: 12rem;
      }
    }
  }

  .wl-line:nth-child(even) {
    background-color: $off-white;
  }
}

.wl-finalize-item {
  display: flex;
  justify-content: space-between;
  padding: .25rem;

  .wl-finalize-description {
    width: 80%;
  }
}

.wl-finalize-item:nth-child(odd) {
  background-color: $off-white;
}
