//Full calendar library styles
.fc-event-container {
  .fc-content {
    font-size: 1.1em;
    padding: .15em;
  }
}

.fc-view {
  .fc-popover {
    font-size: 1.3em;
    font-weight: 800;
    width: auto;
  }
}
